import React from "react";
import { graphql } from "gatsby";

import { URL } from "../constants";

import Layout from "../components/Layout";
import usePageView from "../utils/usePageView";
import SEO from "../components/seo";
import { Flex } from "../style/Grid";
import TileButton from "../components/TileButton";
import { Text } from "../style/Text";
import CareerGrowth from "../components/CareerGrowth";
import { Container, Wrapper } from "../style/Grid";
import Bubble from "../components/Bubble";
import { Heading } from "../style/Heading";
import Header from "../components/Header";
import TriangleBlur from "../style/Header/triangleBlur";
import TopicLinks from "../components/TopicLinks";

const EducationPage = ({ data, location }) => {
  usePageView(location.pathname, "Vzdělávání", "");
  const {
    mdx: {
      frontmatter: {
        metaTitle,
        metaDescription,
        headerImage,
        title,
        description,
        educationList,
        peoples,
      },
    },
    careerImage1,
    careerImage2,
    careerImage3,
    careerImage4,
    careerImage5,
    careerImage6,
  } = data;

  const topicLinks =
    educationList?.map((e) => ({
      heading: e.title,
      link: `${URL.education}/${e.link}`,
      image: e.image,
    })) || [];

  const careerGrowthData = [
    {
      position: "Intern",
      description: "Při škole",
      text: "Chvíli v práci, chvíli ve škole. Přidat se k nám můžeš už jako student. Nebudeš stát stranou, zapojíme tě rovnou do projektů. Zvládat se to dá, nemusíš mít obavy. Získáš smysluplnou praxi  a uděláš si dokonalou představu, o čem je poradenství EY.",
      image: careerImage1,
    },
    {
      position: "Junior konzultant",
      description: "Hned po škole",
      text: "Projekty, školení, projekty, školení. Od začátku se budeš hodně učit, plnit dílčí úkoly, konzultovat s týmem a chodit na schůzky s klienty. Budeš hodně pracovat, ale určitě si najdeš čas na koníčky. Poznáš spoustu kolegů, ze kterých se brzy stanou skvělí kamarádi.",
      image: careerImage2,
    },
    {
      position: "Senior konzultant",
      description: "Za 2 - 3 roky",
      text: "Dostaneš na starost tým juniorů a přijdou nové, komplikovanější projekty. Začneš ve větším cestovat po Česku i do ciziny a stane se z tebe skutečný odborník, jehož slovo má váhu. Budeš mít větší zodpovědnost jak vůči kolegům, tak klientům.",
      image: careerImage3,
    },
    {
      position: "Manager",
      description: "Za 5 - 6 let",
      text: "Poradenství bude tvůj životní styl. Budeš perfektně znát procesy, mít dokonalý přehled o dění v týmu a každý den nahlédneš do firem z různých odvětví. Staneš se tváří našich projektů a postupně začneš rozvíjet kontakty a přinášet do EY byznys.",
      image: careerImage4,
    },
    {
      position: "Senior manager",
      description: "Po několika letech jako manažer",
      text: "Budeš mít komplexní práci. Od kontaktů s klienty přes vyhledávání nového byznysu až po zodpovědnost za kvalitu projektů a řízení celého týmu. Budeš předávat své zkušenosti kolegům a spolupodílet se na strategických rozhodnutích firmy.",
      image: careerImage5,
    },
    {
      position: "Partner",
      description: "Možná jednou",
      text: "Tvoje role bude víc strategická a obchodní. Dohlédneš na fungování týmu a spokojenost klientů. Budeš hledat dlouhodobé trendy, přednášet o nich, školit kolegy, domlouvat nové projekty a řídit směrování poradenství v Česku i v rámci celého EY.",
      image: careerImage6,
    },
  ];

  return (
    <Layout location={location}>
      <SEO title={metaTitle} description={metaDescription} />
      <Header
        bgImage={headerImage.image}
        minHeight={["450px", null, "751px", null, null]}
        bottomComponents={<TriangleBlur />}
        containerJustifyContent="flex-end"
        shadowAfter={true}
      >
        <Heading
          as="h1"
          color="white.100"
          fontSize={[5, 6, 6, "48px", null]}
          fontWeight="300"
          lineHeight="1.3"
          maxWidth={["320px", "500px", "650px", null, null]}
          mb={["24px", null, null, null, null]}
        >
          {title}
        </Heading>
        <Text
          color="white.100"
          fontWeight="300"
          fontSize={[2, null, null, 2, null]}
          lineHeight="24px"
          maxWidth={["280px", "300px", "350px", "425px", null]}
          mb={["45px", null, "84px", 6, null]}
        >
          {description}
        </Text>
      </Header>

      {topicLinks.length > 0 && <TopicLinks as="section" items={topicLinks} />}

      {peoples && peoples.length > 0 && (
        <Wrapper as="section" bg="white.100" overflow="hidden">
          <Container
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="center"
            mt={["40px", null, null, "80px", null]}
            mb={["20px", "40px", null, "50px", null]}
          >
            {peoples.map((message, index) => (
              <Bubble
                left={!(index % 2)}
                light={true}
                wide={true}
                description={message.description}
                name={message.name}
                position={message.position}
                department={message.department}
                image={message.image}
                button={
                  message.button && message.button.link && message.button.text
                    ? {
                        href: message.button.link,
                        label: message.button.text,
                      }
                    : undefined
                }
                heading={message.heading}
              />
            ))}
          </Container>
        </Wrapper>
      )}

      <CareerGrowth careers={careerGrowthData} />

      <section>
        <Flex>
          <TileButton
            width="100%"
            tileColor="light"
            tileOrientation="right"
            to={URL.jobs}
          >
            <Flex flexDirection="column" alignItems="flex-end">
              <Text
                fontSize={["18px", null, "5", null, null]}
                color="EYOffBlack.tile"
              >
                Chci se přidat k EY
              </Text>
              <Text
                fontSize={[1, null, 2, null, null]}
                fontWeight="300"
                color="EYOffBlack.tile"
                opacity=".7"
                mt={["8px", null, "24px", null, null]}
              >
                Prohlédni si aktuální volné pozice
              </Text>
            </Flex>
          </TileButton>
        </Flex>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query EducationPageQuery {
    mdx(frontmatter: { templateKey: { eq: "education" } }) {
      frontmatter {
        metaTitle
        metaDescription
        title
        description
        headerImage {
          image {
            childImageSharp {
              gatsbyImageData(width: 1920, quality: 90)
            }
          }
          imageAlt
        }
        educationList {
          title
          link
          image {
            id
            childImageSharp {
              gatsbyImageData(width: 1920, quality: 90)
            }
          }
        }
        peoples {
          image {
            childImageSharp {
              gatsbyImageData(width: 88, height: 88, quality: 90, layout: FIXED)
            }
          }
          button {
            link
            text
          }
          heading
          name
          position
          description
          department
        }
      }
    }
    careerImage1: file(relativePath: { eq: "career/1.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(width: 1350, quality: 90, layout: FIXED)
      }
    }
    careerImage2: file(relativePath: { eq: "career/2.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(width: 1266, quality: 90, layout: FIXED)
      }
    }
    careerImage3: file(relativePath: { eq: "career/3.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(width: 1266, quality: 90, layout: FIXED)
      }
    }
    careerImage4: file(relativePath: { eq: "career/4.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(width: 1350, quality: 90, layout: FIXED)
      }
    }
    careerImage5: file(relativePath: { eq: "career/5.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(width: 1266, quality: 90, layout: FIXED)
      }
    }
    careerImage6: file(relativePath: { eq: "career/6.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(width: 1266, quality: 90, layout: FIXED)
      }
    }
  }
`;

export default EducationPage;
